<template>
  <div>
    <portal to="page-top-title">New Label</portal>
    <portal to="page-top-right"/>

    <box-form action="create" data-prop="formData" url="/campaigns/labels" redirect="campaigns-labels-list" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="3" required/>
          </column-input>
          <column-input :md="2">
            <form-group-check name="active" :model="formData.active"/>
          </column-input>
        </row>
        <row>
          <column-input :md="12" :sm="12">
            <form-group-textarea name="description" :rows="3" :model="formData.description" :max="255" required>
              <p slot="help">* 255 characters max.</p>
            </form-group-textarea>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupTextarea from '@/views/components/form/FormGroupTextarea'
import Form from '@/modules/utils/Form'

export default {
  name: 'CampaignsLabelsNew',
  metaInfo () {
    return { title: 'Campaigns | Labels | New' }
  },
  components: {
    BoxForm,
    FormGroupInput,
    FormGroupCheck,
    FormGroupTextarea
  },
  data () {
    return {
      formData: {
        name: Form.types.input(),
        description: Form.types.input(),
        active: Form.types.boolean(true)
      }
    }
  }
}
</script>
